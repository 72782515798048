<template>
    <div class="sign-offline-container">
        <p>{{ $t('APPLICATION.SIGNATURE.OFFLINE.INFO_TEXT') }}</p>
        <div class="package-action-button-container">
            <div class="package-action-button cursor-pointer" @click.stop="downloadDocuments">
                <div class="package-action-button__icon">
                    <i class="fas fa-cloud-download-alt"></i>
                </div>
                <div class="package-action-button__title">
                    {{ $t('APPLICATION.SIGNATURE.OFFLINE.ACTION_DOWNLOAD_TITLE') }}
                </div>
                <div class="package-action-button__subtitle">
                    {{ $t('APPLICATION.SIGNATURE.OFFLINE.ACTION_DOWNLOAD_SUBTITLE') }}
                </div>
            </div>
            <div class="package-action-button cursor-pointer"
                :class="['upload-section', { disabled: !isDownloaded }]"
                @click.stop="uploadDocuments">
                <div class="package-action-button__icon"><i class="fas fa-cloud-upload-alt"></i></div>
                <div class="package-action-button__title">{{
                        $t('APPLICATION.SIGNATURE.OFFLINE.ACTION_UPLOAD_TITLE')
                    }}
                </div>
                <div class="package-action-button__subtitle">
                    {{ $t('APPLICATION.SIGNATURE.OFFLINE.ACTION_UPLOAD_SUBTITLE') }}
                </div>
            </div>
            <form novalidate="novalidate" id="st_upload_offline_file_form">
                <div class="form-group">
                    <b-form-file
                        v-show="false"
                        placeholder=""
                        accept=".pdf"
                        ref="upload_documents"
                        name="upload_documents"
                        @change="sendDocuments"
                    ></b-form-file>
                </div>
            </form>
        </div>
        <div class="timeline-container">
            <ul class="timeline">
                <li
                    :class="[
                        'timeline__step',
                        {
                        active: !isDownloaded,
                        checked: isDownloaded,
                        fix: isDownloaded && isSigned,
                        },
                    ]"
                >
                    <div class="timeline__step-icon"><i class="fas fa-file-alt"></i></div>
                    <div class="timeline__step-marker">
                        <span v-if="!isDownloaded">1</span>
                        <i v-else class="fas fa-check text-white"></i>
                    </div>
                    <div class="timeline__step-title">
                        {{ $t('APPLICATION.SIGNATURE.OFFLINE.STEP_DOWNLOAD') }}
                    </div>
                </li>
                <li
                    :class="[
                        'timeline__step',
                        {
                        active: isDownloaded && !isSigned,
                        checked: isSigned,
                        fix: isSigned && isVerified,
                        },
                    ]"
                >
                    <div class="timeline__step-icon"><i class="fas fa-pencil-alt"></i></div>
                    <div class="timeline__step-marker">
                        <span v-if="!isSigned">2</span>
                        <i v-else class="fas fa-check text-white"></i>
                    </div>
                    <div class="timeline__step-title">{{ $t('APPLICATION.SIGNATURE.OFFLINE.STEP_SIGN') }}</div>
                </li>
                <li
                    :class="[
                        'timeline__step',
                        {
                        active: isSigned && !isVerified,
                        checked: isVerified,
                        fix: isVerified && isChecked,
                        },
                    ]"
                >
                    <div class="timeline__step-icon">
                        <i class="fas fa-search-plus"></i>
                    </div>
                    <div class="timeline__step-marker">
                        <span v-if="!isVerified">3</span>
                        <i v-else class="fas fa-check text-white"></i>
                    </div>
                    <div class="timeline__step-title">
                        {{ $t('APPLICATION.SIGNATURE.OFFLINE.STEP_VERIFY') }}
                    </div>
                </li>
                <li
                    :class="[
                        'timeline__step',
                        {
                        active: isVerified && !isChecked,
                        checked: isChecked,
                        fix: isChecked,
                        },
                    ]"
                >
                    <div class="timeline__step-icon">
                        <i class="fas fa-clipboard-check"></i>
                    </div>
                    <div class="timeline__step-marker">
                        <span v-if="!isChecked">4</span>
                        <i v-else class="fas fa-check text-white"></i>
                    </div>
                    <div class="timeline__step-title">
                        {{ $t('APPLICATION.SIGNATURE.OFFLINE.STEP_CHECKED') }}
                    </div>
                </li>
            </ul>
        </div>
    </div>
</template>
<script>
import { mapActions, mapGetters } from 'vuex';
import KTUtil from '@/assets/js/components/util';
import formValidation from '@/assets/plugins/formvalidation/dist/es6/core/Core';
// FormValidation plugins
import Trigger from '@/assets/plugins/formvalidation/dist/es6/plugins/Trigger';
import Bootstrap from '@/assets/plugins/formvalidation/dist/es6/plugins/Bootstrap';
import Message from '@/shared/message/message';

export default {
    name: 'SignatureOffline',
    props: {
        isApplicationSigned: Boolean,
    },
    data() {
        return {
            isSigned: false,
            isVerified: false,
            isChecked: false,
            showConfirmationPopup: true,
            successfullyUploaded: false,
            upload_documents: null,
        };
    },
    computed: {
        ...mapGetters({
            record: 'applications/form/record',
            uploadToken: 'applications/form/uploadAccessToken',
            isOfflineSignPdfDownloaded: "applications/form/isOfflineSignPdfDownloaded",
        }),
        isDownloaded() {
            return !!this.isOfflineSignPdfDownloaded;
        },
    },
    mounted() {
        const form = KTUtil.getById('st_upload_offline_file_form');
        this.fv = formValidation(form, {
            fields: {
                upload_documents: {
                    validators: {
                        file: {
                            extension: 'pdf',
                            type: 'application/pdf',
                            message: this.$t('VALIDATION.PDF_FIELD')
                        },
                    }
                }
            },
            plugins: {
                trigger: new Trigger(),
                bootstrap: new Bootstrap(),
            },
        });
    },
    methods: {
        ...mapActions({
            doUpload: 'applications/form/upload',
            doUpdate: 'applications/form/update',
            finalizeSigning: 'applications/form/finalizeOfflineSigning',
            checkOfflineSigning: 'applications/form/checkOfflineSigning'
        }),
        downloadDocuments() {
            if (!this.record?.is_signed) {
                this.$emit('downloadFiles');
            }
        },
        uploadDocuments() {
            if (!this.record?.is_signed) {
                // Avoid click again on b-form-file, it's contained under upload-section
                if (this.showConfirmationPopup || !this.successfullyUploaded) {
                    this.$alert({
                        type: 'warning',
                        text: this.$t('APPLICATION.SIGNATURE.OFFLINE.UPLOAD_NOTIFICATION'),
                        confirmButtonText: this.$t('GENERAL.YES'),
                        cancelButtonText: this.$t('GENERAL.NO'),
                        hasConfirmation: true,
                        confirmCallback: () => {
                            this.showConfirmationPopup = false;
                            this.$refs.upload_documents.$el.childNodes[0].click();
                        },
                        dismissCallback: () => {
                            this.showConfirmationPopup = true;
                        }
                    })
                }
            }
        },
        async sendDocuments(ev) {
            const validate = await this.fv.validate();
            if (validate === 'Valid') {
                const file = ev.target.files;
                let newPdfFormData = new FormData();
                newPdfFormData.append('files', file[0]);

                let uploadedFiles = await this.doUpload({
                    files: newPdfFormData,
                    uploadAccessToken: this.uploadToken
                });

                try {
                    const newlyAddedPdfIsSigned = await this.checkOfflineSigning({
                        id: this.record.id,
                        new_pdf_id: uploadedFiles[0].id
                    });

                    if (uploadedFiles && this.record) {
                        let offlineSign = this.record.offlineSign;
                        let lastIndex = offlineSign.files.length - 1;
                        let lastOfflineSign = offlineSign.files[lastIndex];

                        lastOfflineSign.file_id = uploadedFiles[0].id;
                        this.record.offlineSign.files[lastIndex] = lastOfflineSign;

                        const applicationUpdated = await this.doUpdate({
                            id: this.record.id,
                            values: {offlineSign: this.record.offlineSign, is_signed: true},
                            loadStaff: false,
                            autosave: true
                        });

                        this.successfullyUploaded = true;
                        //verification step has to be implemented on BE
                        this.isSigned = newlyAddedPdfIsSigned;

                        setTimeout(() => {
                            //implement this when BE is done
                            this.isVerified = newlyAddedPdfIsSigned;
                        }, 1000);

                        setTimeout(async () => {
                            this.isChecked = newlyAddedPdfIsSigned;
                            //update archive status
                            await this.finalizeSigning({
                                id: this.record.id,
                                new_pdf_id: lastOfflineSign.file_id,
                            });

                            this.$emit('isSigned', applicationUpdated.is_signed);
                        }, 2000);
                    }
                } catch(error) {
                    this.successfullyUploaded = false;
                    this.$refs.upload_documents.reset();
                }
            }
            else {
                this.successfullyUploaded = false;
                Message.error('ERRORS.INVALID_FILE');
                this.$refs.upload_documents.reset();
            }
        }
    },
    created() {
        if (this.record) {
            this.isSigned = !!this.record.is_signed;
            this.isChecked = !!this.record.is_signed;
            this.isVerified = !!this.record.is_signed;
        }
    }
};
</script>
